import React from "react";
import "./Audio.scss";

export default ({ data }) => {
  return (
    <div className="c-audio">
      {data.Title && <div className="c-audio__title">{data.Title}</div>}
      <div className="c-audio__content">
        <audio controls>
          <source src={data.url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
};
