import React from 'react'
import './Legal.scss'

export default () => {
  return (
    <div className="c-legal">
      <span className="c-legal__heading">
        Important information
      </span>
      <div className="c-legal__content">
        Our app contains general information about recovering from abdominal surgery. It is intended to add to the information given by your doctor or other healthcare professional, not to replace it, and should not be taken as health advice. Never ignore the recommendations of your doctor or healthcare professional, or delay in seeking help, because of something you have read in this app. If you choose to rely on information provided by this app, you do so solely at your own risk. We accept no responsibility for the content of external links, which are also followed at your own risk. Should you have any concerns about your recovery, or you feel unwell, call your doctor, go to your nearest hospital emergency department or dial 000 as appropriate.
      </div>
    </div>
  )
}