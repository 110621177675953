import React from 'react'
import { Title } from '../../components'
import './AboutFeatures.scss'

export default ({ data }) => {
  return (
    <div className="c-features">
      {data.title &&
        <div className="c-features__title">
          <Title data={{ title: data.title }} />
        </div>
      }
      {data.feature &&
        <div className="c-features__list">
          {data.feature.map((feature, index) =>
            <div key={index} className="c-features__item">
              <div className="c-feature">
                <div className="c-feature__icon">
                  <img src={process.env.REACT_APP_API_BASE_URL + feature.icon.url} alt="" />
                </div>
                <div className="c-feature__text">{feature.text}</div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}