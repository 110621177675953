import React from 'react'
import './Loader.scss'

export default () => {
  return (
    <div className="c-loader">
      <div className="c-loader__logo">
        <img src="/assets/images/loader-logo@3x.png" alt=""/>
      </div>
    </div>
  )
}