import React from 'react'
import './Image.scss'

export default ({ data }) => {
  return (
    <div className="c-image">
      {data.title &&
        <div className="c-image__title h4">{data.title}</div>
      }
      <div className="c-image__source">
        <img src={process.env.REACT_APP_API_BASE_URL + data.source.url} alt='' />
      </div>
      {data.description &&
        <div className="c-image__description h6">{data.description}</div>
      }
    </div>
  )
}