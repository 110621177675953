import React, { Component } from "react";
import { Legal, Block, Loader } from "../../components";
import api from "../../api";

export default class extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    const { pageId } = this.props;

    api.page.getById(pageId).then((response) => {
      this.setState({
        data: response.data,
      });
    });
  }

  render() {
    const { data } = this.state;

    if (!data) {
      return <Loader />;
    }

    return (
      <div className="c-page">
        <div className="c-page__content">
          <div className="c-content">
            <div className="c-content__section">
              <h2 className="c-content__title">{data.title}</h2>
            </div>
            {data.content.map((block, index) => (
              <Block key={index} data={block} />
            ))}
          </div>
        </div>
        <div className="c-page__bottom">
          <div className="c-page__inner">
            <div className="c-page__legal">
              <Legal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
