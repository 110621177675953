import React, { Component } from "react";
import { Block } from "../../components";
import "./Article.scss";

export default class extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  toggle = () => {
    if (this.ref.current.classList.contains("-expanded")) {
      this.ref.current.classList.remove("-expanded");
    } else {
      this.ref.current.classList.add("-expanded");
    }
  };

  render() {
    const { article } = this.props;

    return (
      <div className="c-article" ref={this.ref}>
        <div className="c-article__head" onClick={this.toggle}>
          <div className="c-article__icon">
            <img src={process.env.REACT_APP_API_BASE_URL + article.icon.url} alt="" />
          </div>
          <div className="c-article__info">
            <span>{article.title}</span>
            <p>{article.meta}</p>
          </div>
          <div className="c-article__arrow">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 9.5L12 15.5L18 9.5"
                stroke="#0A0A0A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="c-article__content">
          <div className="c-content">
            <div className="c-content__section">
              <div className="c-title">
                <h2 className="c-title__value">{article.title}</h2>
              </div>
            </div>
            {article.content.map((block, index) => (
              <Block key={index} data={block} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
