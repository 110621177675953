import React from 'react'
import './Home.scss'

export default () => {
  return (
    <div className="c-home">
      <div className="c-home__content">
        <div className="c-home__logo">
          <img src="/assets/images/loader-logo@3x.png" alt="BellyBands" />
        </div>
        <div className="c-home__title">BellyBands</div>
      </div>
    </div>
  )
}