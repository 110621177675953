import React from 'react'
import { Title, Text } from '../../components'
import './AboutStory.scss'

export default ({ data }) => {
  return (
    <div className="c-story">
      {data.title &&
        <div className="c-story__title">
          <Title data={{ title: data.title }} />
        </div>
      }
      <div className="c-story__photo">
        <img src={process.env.REACT_APP_API_BASE_URL + data.photo.url} alt="" />
        <div className="c-story__author">
          <div className="c-story__authorName">{data.name}</div>
          <div className="c-story__authorPosition">{data.position}</div>
        </div>
      </div>
      {data.text &&
        <div className="c-story__text">
          <Text data={{ text: data.text }} />
        </div>
      }
    </div>
  )
}