import React from 'react'
import './AboutCarousel.scss'

export default ({ data }) => {
  return (
    <div className="c-carousel">
      <div className="c-carousel__items">
        {data.slide.map((slide, index) =>
          <div className="c-carousel__item" key={index}>
            <div className="c-carousel__image">
              <img src={process.env.REACT_APP_API_BASE_URL + slide.image.url} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}