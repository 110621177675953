import React, { useState } from 'react'
import className from 'classnames'
import './Switch3.scss'

export default ({ data }) => {
  const [slide, setSlide] = useState(1)

  return (
    <div className="c-switch-3">
      { data.heading &&
        <div className="c-switch-3__title">{data.heading}</div>
      }
      <div className="c-switch-3__slider">
        <div className="c-switch-3__sliderWrapper">
          <div className="c-switch-3__sliderPath">
            <img src="/assets/images/switch_3_path@3x.png" alt="" />
          </div>
          <div className={className({ 'c-switch-3__sliderButton': true, '-first': true, '-active': slide === 1 })} onClick={() => setSlide(1)}></div>
          <div className={className({ 'c-switch-3__sliderButton': true, '-second': true, '-active': slide === 2 })} onClick={() => setSlide(2)}></div>
          <div className={className({ 'c-switch-3__sliderButton': true, '-third': true, '-active': slide === 3 })} onClick={() => setSlide(3)}></div>
        </div>
        <div className="c-switch-3__preview">
          <div className="c-switch-3__previewIcon">
            { data['icon_1'].url && <img className={className({'-active': slide === 1})} src={process.env.REACT_APP_API_BASE_URL + data['icon_1'].url} alt="" /> }
            { data['icon_2'].url && <img className={className({'-active': slide === 2})} src={process.env.REACT_APP_API_BASE_URL + data['icon_2'].url} alt="" /> }
            { data['icon_3'].url && <img className={className({'-active': slide === 3})} src={process.env.REACT_APP_API_BASE_URL + data['icon_3'].url} alt="" /> }
          </div>
          <div className="c-switch-3__previewTitle">{data['title_' + slide]}</div>
        </div>
      </div>
      <div className="c-switch-3__content" dangerouslySetInnerHTML={{ __html: data['content_' + slide] }}></div>
    </div>
  )
}