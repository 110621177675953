import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import './styles/app.scss'

smoothscroll.polyfill()

ReactDOM.render(
  <App />,
  document.getElementById('root')
)