import React from 'react'
import './Product.scss'

export default ({ data }) => {
  return (
    <div className="c-product">
      {data.image &&
        <div className="c-product__image">
          <img src={process.env.REACT_APP_API_BASE_URL + data.image.url} alt="" />
        </div>
      }
      <div className="c-product__text">{data.text}</div>
      <a className="c-button c-product__button" href={data.button_link}>{data.button_text}</a>
    </div>
  )
}