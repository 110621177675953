import React from 'react'
import './AboutLinks.scss'

export default ({ data }) => {
  return (
    <div className="c-textLinks">
      {data.item.map(item =>
        <div className="c-textLinks__item">
          <div className="c-textLink">
            <div className="c-textLink__title">{item.title}</div>
            <a className="c-textLink__link c-link" href={item.link_url}>{item.link_text}</a>
          </div>
        </div>
      )}
    </div>
  )
}