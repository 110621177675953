import React from 'react'
import { Title, Text } from '../../components'
import './AboutThanks.scss'

export default ({ data }) => {
  return (
    <div className="c-thanks">
      {data.title &&
        <div className="c-thanks__title">
          <Title data={{ title: data.title }} />
        </div>
      }
      <div className="c-thanks__textTop">
        <Text data={{ text: data.text_top }} />
      </div>

      {data.profiles &&
        <div className="c-thanks__profiles">
          <div className="c-profiles">
            {data.profiles.map((profile, index) =>
              <div className="c-profiles__item" key={index}>
                <div className="c-profile">
                  {profile.photo &&
                    <div className="c-profile__photo">
                      <img src={process.env.REACT_APP_API_BASE_URL + profile.photo.url} alt="" />
                    </div>
                  }
                  <div className="c-profile__name">{profile.name}</div>
                  <div className="c-profile__position">{profile.position}</div>
                  <a className="c-profile__link c-link" href={profile.link_url}>{profile.link_title}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      }

      <div className="c-thanks__textTop">
        <Text data={{ text: data.text_bottom }} />
      </div>
    </div>
  )
}