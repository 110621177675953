import http from './http'

function getAll () {
  return http({
    method: 'GET',
    url: '/categories'
  })
}

function getById (id) {
  return http({
    method: 'GET',
    url: '/categories/' + id
  })
}

export {
  getAll,
  getById
}
