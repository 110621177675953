import React from 'react'
import './Tip.scss'

export const Tip = (props) => {
  return (
      <div className="tip">
          <div className="tip__head">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 16.0654V12.0654M12 8.06543H12.01M22 12.0654C22 17.5883 17.5228 22.0654 12 22.0654C6.47715 22.0654 2 17.5883 2 12.0654C2 6.54258 6.47715 2.06543 12 2.06543C17.5228 2.06543 22 6.54258 22 12.0654Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span>Note</span>
          </div>
          <div className="tip__content">
              {props.text}
          </div>
      </div>
  )
}