import React from 'react'
import './Definition.scss'

export default ({ data }) => {
  return (
    <div className="c-definition">
      {data.title &&
        <div className="c-definition__title h6">{data.title}</div>
      }
      <div className="c-definition__content" dangerouslySetInnerHTML={{ __html: data.description }}></div>
    </div>
  )
}