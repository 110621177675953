import React from "react";
import "./List.scss";

export default ({ data }) => {
  return (
    <div className="list">
      {data.title && <div className="list__title">{data.title}</div>}
      <div className="list__items">
        {data.item.map((item, index) => (
          <div className="list__item" key={index}>
            <div className="list__itemNumber">{index + 1}</div>
            <div className="list__itemText">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
