import React from 'react'
import './Video.scss'

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

export default ({ data }) => {
  return (
    <div className="c-video">
      { data.title &&
        <div className="c-video__title">{data.title}</div>
      }
      <div className="c-video__content">
        <div className="c-video__wrapper">
          <iframe title={data.title || ''} width="560" height="349" src={`//www.youtube.com/embed/${getId(data.url)}?rel=0&hd=1`} frameBorder="0"
                  allowFullScreen></iframe>
        </div>
      </div>
    </div>
  )
}