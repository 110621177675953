import React, { Component } from 'react'
import api from '../../api'
import './Notifications.scss'
import className from 'classnames'

export default class extends Component {
  state = {
    title: '',
    text: '',
    password: '',
    loading: false,
    success: false
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (!this.state.title || !this.state.text || !this.state.password) {
      alert('Fill in all fields')
      return
    }

    this.setState({
      ...this.state,
      loading: true
    })

    api.notification.create({
      password: this.state.password,
      title: this.state.title,
      text: this.state.text
    })
      .then(response => {
        this.setState({
          success: true
        })
        this.resetForm()
      })
      .catch(e => {
        alert('Error')
        this.resetForm()
      })
  }

  handleChange = (param, e) => {
    this.setState({
      ...this.state,
      [param]: e.target.value
    })
  }

  resetForm = () => {
    this.setState({
      password: '',
      title: '',
      text: '',
      loading: false
    })
  }

  render() {
    return(
      <div className="c-page">
        <div className="c-page__page">
          <div className="c-content">
            <div className="c-contact">
              <div className="c-contact__photo">
                <img src="/assets/images/photo_contact_us@3x.png" alt="" />
              </div>
              <div className="c-contact__heading">
                <h2>Here you can send a push notification</h2>
              </div>
              <div
                className={className({ 'c-contact__form': true, '-loading': this.state.loading, '-active': !this.state.success })}
              >
                <div className="c-contact__field">
                  <input value={this.state.title} onChange={this.handleChange.bind(this, 'title')} type="text" className="c-contact__input" placeholder="Title" />
                </div>
                <div className="c-contact__field">
                  <input value={this.state.text} onChange={this.handleChange.bind(this, 'text')} type="text" className="c-contact__input" placeholder="Text" />
                </div>
                <div className="c-contact__field">
                  <input value={this.state.password} onChange={this.handleChange.bind(this, 'password')} type="text" className="c-contact__input" placeholder="Password (grant access)" />
                </div>
                <div className="c-contact__control">
                  <button className="c-button" onClick={this.handleSubmit}>Send Notification</button>
                </div>
              </div>
              <div
                className={className({ 'c-contact__success': true, '-active': this.state.success })}
              >
                <div className="c-contact__successMessage">
                  <img src="/assets/icons/icon-success@3x.png" alt="" />
                  <span>Your notification was sent!</span>
                </div>
                <div className="c-contact__control">
                  <button className="c-button" onClick={() => this.setState({ success: false })}>Send One More Notification</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}