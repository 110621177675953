import { Article } from "./index";
import React from "react";

export const Articles = ({ items }) => {
  return (
    <div className="articles">
      <span className="articles__label">Learn more:</span>
      {items
        .sort((a, b) => a.order - b.order)
        .map((article, index) => (
          <Article key={index} article={article} />
        ))}
    </div>
  );
};
