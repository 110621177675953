import React from "react";
import {
  Text,
  Image,
  Definition,
  References,
  List,
  Video,
  Quote,
  Message,
  Product,
  Switch3,
  Switch5,
  AboutCarousel,
  AboutFeatures,
  AboutThanks,
  AboutStory,
  AboutLinks,
  Audio,
} from "./components";
import "./Block.scss";

const Block = ({ data }) => {
  if (data.__component === "blocks.text") {
    return <Text data={data} />;
  }

  if (data.__component === "blocks.image") {
    return <Image data={data} />;
  }

  if (data.__component === "blocks.definition") {
    return <Definition data={data} />;
  }

  if (data.__component === "blocks.references") {
    return <References data={data} />;
  }

  if (data.__component === "blocks.list") {
    return <List data={data} />;
  }

  if (data.__component === "blocks.video") {
    return <Video data={data} />;
  }

  if (data.__component === "blocks.audio") {
    return <Audio data={data} />;
  }

  if (data.__component === "blocks.switch-3") {
    return <Switch3 data={data} />;
  }

  if (data.__component === "blocks.switch-5") {
    return <Switch5 data={data} />;
  }

  if (data.__component === "blocks.message") {
    return <Message data={data} />;
  }

  if (data.__component === "blocks.product") {
    return <Product data={data} />;
  }

  if (data.__component === "about.links") {
    return <AboutLinks data={data} />;
  }

  if (data.__component === "about.story") {
    return <AboutStory data={data} />;
  }

  if (data.__component === "about.features") {
    return <AboutFeatures data={data} />;
  }

  if (data.__component === "about.thanks") {
    return <AboutThanks data={data} />;
  }

  if (data.__component === "about.carousel") {
    return <AboutCarousel data={data} />;
  }

  if (data.__component === "nested.quote") {
    return <Quote data={data} />;
  }

  return <div>NO RENDERER</div>;
};

export default Block;
