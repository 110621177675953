import React from 'react'
import './Quote.scss'

export default ({ data }) => {
  return (
    <div className="c-quote">
      {data.title &&
        <div className="c-quote__title">{data.title}</div>
      }
      <div className="c-quote__description">{data.description}</div>
      <div className="c-quote__icon">
        <svg width="34" height="28">
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-6 -9)">
              <g fill="currentColor" fillRule="nonzero">
                <path d="M14.75 23.567H20V37H6V26.835C6 15.73 10.667 9.787 20 9v5.037c-3.5.938-5.25 3.63-5.25 8.078v1.452zm20 0H40V37H26V26.835C26 15.73 30.667 9.787 40 9v5.037c-3.5.938-5.25 3.63-5.25 8.078v1.452z"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="c-quote__text">{data.text}</div>
    </div>
  )
}