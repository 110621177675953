import http from './http'

function create (data) {
  return http({
    method: 'post',
    url: '/notifications',
    data
  })
}

export {
  create
}
