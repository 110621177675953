import React, { Component } from "react";
import { Legal, Tip, Block, Loader, Articles } from "../../components";
import api from "../../api";

export default class extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    const { pageId } = this.props;

    api.category.getById(pageId).then((response) => {
      this.setState({
        data: response.data,
      });
    });
  }

  render() {
    const { data } = this.state;

    if (!data) {
      return <Loader />;
    }

    return (
      <div className="c-page">
        <div className="c-page__content">
          <div className="c-content">
            <div className="c-content__section">
              <h2 className="c-content__title">{data.title}</h2>
            </div>
            <div className="c-content__section" dangerouslySetInnerHTML={{ __html: data.intro }}></div>
            {data.content.map((block, index) => (
              <div className="c-content__section" key={index}>
                <Block data={block} />
              </div>
            ))}
          </div>
        </div>
        <div className="c-page__bottom">
          {data.tip.length > 0 && <Tip text={data.tip} />}
          {data.articles.length > 0 && <Articles items={data.articles} />}
          <div className="c-page__legal">
            <Legal />
          </div>
        </div>
      </div>
    );
  }
}
