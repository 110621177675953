import React from 'react'
import { Home, Prevention, Recovery, ScarTherapy, Surgery, About, Resources, Notifications } from './screens'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/category/prevention' component={Prevention} />
        <Route exact path='/category/recovery' component={Recovery} />
        <Route exact path='/category/scar-therapy' component={ScarTherapy} />
        <Route exact path='/category/surgery' component={Surgery} />
        
        <Route exact path='/page/about' component={About} />
        <Route exact path='/page/resources' component={Resources} />
        <Route exact path='/page/notifications' component={Notifications} />
        
        <Route path="*" render={() => <h2>Not Found</h2>} />
      </Switch>
    </Router>
  )
}